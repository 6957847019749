import React from 'react';
import PropTypes from 'prop-types';

import img0 from '../../images/tbiImage1.png';
import img1 from '../../images/tbiImage2.png';
import img2 from '../../images/tbiImage3.png';
import img3 from '../../images/tbiImage4.png';
import img4 from '../../images/tbiImage5.png';
import ico0 from '../../images/tbiImageIcon1.png';
import ico1 from '../../images/tbiImageIcon2.png';
import ico2 from '../../images/tbiImageIcon3.png';

//const lorem = 'Lorem Ipsum is simply dummy and typesetting industry.';



class Component extends React.Component {

    render() {
        // console.log(this.props.channel); 
        const data = [
            [
                { img: img0, text: 'phone_no', link: 'tel:003216555255' },
                { img: img1, text: 'address_loc', link: 'https://www.google.be/maps/place/Rijweg+169,+3020+Herent/@50.9044141,4.664942,17z/data=!3m1!4b1!4m5!3m4!1s0x47c16063b5b353c3:0x1b19cd30a0d4e8de!8m2!3d50.9044107!4d4.6671307' },
                { img: img2, text: 'email_id', link: 'mailto:service@doitforme.services' },
                { img: img3, text: 'Drop Request', link: `/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/contact` },
                { img: img4, text: 'Facebook Chat', link: 'https://www.messenger.com/t/thaudoitforme' }
            ],
            [
                { img: ico0, title: 'Matched to your Needs', text: 'We do everything you need to let you enjoy the purchase of your device or product to the fullest' },
                { img: ico1, title: 'Highly Qualified & Trained Professionals', text: 'We only work with professionals who are trained and authorized by the dealer' },
                { img: ico2, title: 'Hassle Free Service Delivery', text: 'We take over the full process from order to delivery, and guarantee an impeccable service' },
            ]
        ];
        
        const { t } = this.props;

        return (
            <div id="contact_us" className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>{t('Get in touch')}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-1">&nbsp;</div>
                        {
                            data[0].map((item) => (
                                <div key={item.text} className="col-2 contact_icons">
                                    <a rel="noopener noreferrer" target="_blank" href={item.link}><img width="65" alt={item.text} src={item.img} /></a>
                                    <a rel="noopener noreferrer" target="_blank" href={item.link}><div className="datalabel">{t(item.text)}</div></a>
                                </div>
                            ))
                        }
                        <div className="col-1">&nbsp;</div>
                    </div>
                    <div className="row touchHighlight">
                            {
                                data[1].map((item) => (
                                    <div className="col-12 col-lg-4 th-box" key={item.title}>
                                        <div className="thb-icon">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <h3>{t(item.title)}</h3>
                                        {t(item.text)}
                                    </div>
                                ))
                            }
                    </div>
                </div>
            </div>
        )
    }
};

Component.propTypes = {
    t: PropTypes.func
};

export default Component;
