import React from 'react';
import PropTypes from 'prop-types';

const data = [
    { data: '112', label: 'Live Services' },
    { data: '+7.600', label: 'Customers Served' },
    { data: '24', label: 'Verified Experts' },
    { data: '9.4', label: 'Average Rating' }
]

class Component extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <div className="data">
                <div className="container">
                    <div className="row">
                        {
                            data.map((item, i) => (
                                <div key={'data' + i} className="col-3">
                                    <div className="datapoint">{item.data}</div>
                                    <div className="datalabel">{t(item.label)}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
};

Component.propTypes = {
    t: PropTypes.func
};

export default Component;
