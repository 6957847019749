
const lorem = {
    long: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui 
        officia deserunt mollit anim id est laborum.`,
    short: `Lorem Ipsum dolor dummmy text`
};

const languages = [
    { code: 'en_US', label: 'English' },
    { code: 'fr_BE', label: 'Français' },
    { code: 'nl_BE', label: 'Nederlands' },
    { code: 'de_DE', label: 'Deutsche' }
];

const countries = [
    { code: 'be', label: 'Belgium' },
    { code: 'nl', label: 'Netherlands' },
    { code: 'lu', label: 'Luxembourg' },
    { code: 'de', label: 'Germany' },
    { code: 'ch', label: 'Switzerland' },
    { code: 'fr', label: 'France' },
];

const channels = [
    { code: 'default',  label: 'DEFAULT' },
    { code: 'robohome', label: 'ROBOHOME' },
    { code: 'welkoop',  label: 'Welkoop', lang: 'nl_BE', country: 'nl' },
    { code: 'worx',     label: 'Worx' },
    { code: 'wolf-garten', label: 'Wolf Garten' },
    { code: 'stihl', label: 'STIHL' },
    { code: 'graham', label: 'Graham'}, 
    { code: 'vente-exclusive', label: 'Vente Exclusive'}, 
    { code: '50five-robomow', label: '50five-robomow'}, 
    { code: '50five-loopo', label: '50five-loopo'}, 
    { code: 'landxcape', label: 'Landxcape'}, 
    { code: 'eurotuin', label: 'Eurotuin'}, 
    { code: 'bosch', label: 'Bosch'}, 
    { code: 'ambrogio', label: 'Ambrogio'}, 
    { code: 'uwrobotmaaierspecialist', label: 'uwrobotmaaierspecialist'}, 
    { code: 'black+decker', label: "BLACK+DECKER"},
    { code: 'zoefrobot',  label: 'ZOEFROBOT' }
];

const cities = [
    { code: 'be-antwerp', country: 'Belgium', city: 'Antwerp' },
    { code: 'be-brussels', country: 'Belgium', city: 'Brussels' },
    { code: 'be-bruges', country: 'Belgium', city: 'Bruges' },
    { code: 'be-Charleroi', country: 'Belgium', city: 'Charleroi'},
    { code: 'be-ghent', country: 'Belgium', city: 'Ghent' },
    { code: 'be-leige', country: 'Belgium', city: 'Liege'},
    { code: 'be-leuven', country: 'Belgium', city: 'Leuven'}
];

const services = [
    { id: 1, brand: 'BOSCH', category: 'Robotic Mower', model: 'INDEGO 1000', price: 199.99, service: 'Installation' },
    { id: 2, brand: 'BOSCH', category: 'Robotic Mower',  model: 'INDEGO 1000 connect', price: 199.99, service: 'Installation' },
    { id: 3, brand: 'BOSCH', category: 'Robotic Mower',  model: 'INDEGO 350', price: 140.00, service: 'Installation' },
    { id: 4, brand: 'BOSCH', category: 'Robotic Mower',  model: 'INDEGO 350 connect', price: 140.00, service: 'Installation' },
    { id: 5, brand: 'BOSCH', category: 'Robotic Mower',  model: 'INDEGO 400', price: 140.00, service: 'Installation' },
    { id: 6, brand: 'BOSCH', category: 'Robotic Mower',  model: 'INDEGO 400 connect', price: 140.00, service: 'Installation' },
    { id: 7, brand: 'BOSCH', category: 'Robotic Mower',  model: 'INDEGO 800', price: 170.00, service: 'Installation' },
    { id: 8, brand: 'BOSCH', category: 'Robotic Mower',  model: 'INDEGO 800 connect', price: 170.00, service: 'Installation' },
    { id: 9, brand: 'BOSCH', category: 'Robotic Mower',  model: 'INDEGO S+ 400', price: 140.00, service: 'Installation' },
    { id: 10, brand: 'WORKX', category: 'Robotic Mower', model: '700', price: 140.00, service: 'Installation' },
    { id: 10, brand: 'WORKX', category: 'Robotic Mower', model: '1000', price: 140.00, service: 'Installation' },
    { id: 10, brand: 'WORKX', category: 'Robotic Mower', model: '2000', price: 140.00, service: 'Installation' },
    { id: 11, brand: 'STIGA', category: 'Robotic Mower', model: 'Autoclip 225S', price: 100.00, service: 'Installation'},
    { id: 11, brand: 'STIGA', category: 'Robotic Mower', model: 'Autoclip 230S', price: 100.00, service: 'Installation'},
    { id: 11, brand: 'STIGA', category: 'Robotic Mower', model: 'Autoclip 528S', price: 100.00, service: 'Installation'},
    { id: 11, brand: 'STIGA', category: 'Robotic Mower', model: 'Autoclip 530S', price: 100.00, service: 'Installation'},
    { id: 11, brand: 'STIGA', category: 'Robotic Mower', model: 'Autoclip 550SG', price: 100.00, service: 'Installation'},
    { id: 11, brand: 'STIGA', category: 'Robotic Mower', model: 'Autoclip M3', price: 100.00, service: 'Installation'},
    { id: 11, brand: 'STIGA', category: 'Robotic Mower', model: 'Autoclip M5', price: 100.00, service: 'Installation'},
    { id: 11, brand: 'STIGA', category: 'Robotic Mower', model: 'Autoclip M7', price: 100.00, service: 'Installation'},
    { id: 12, brand: 'MOWOX', category: 'Robotic Mower', model: 'Classic series RM 30 Li', price: 100.00, service: 'Installation'},
    { id: 13, brand: 'LANDSCAPE', category: 'Robotic Mower', model: 'Landscape', price: 100.00, service: 'Installation'},
    { id: 14, brand: 'WOLF-GARTEN', category: 'Robotic Mower', model: 'M1000', price: 100.00, service: 'Installation'},
    { id: 15, brand: 'STIHL', category: 'Robotic Mower', model: 'MI 422', price: 100.00, service: 'Installation'},
    { id: 16, brand: 'ROBOMOW', category: 'Robotic Mower', model: 'RC304u / Pro', price: 100.00, service: 'Installation'},
    { id: 17, brand: 'CENTRAL PARK', category: 'Robotic Mower', model: 'RO 1000 Wifi', price: 100.00, service: 'Installation'},
];

const statistics = [
    { data: '118', label: 'Live Services' },
    { data: '3 million', label: 'Customers Served'},
    { data: '100,000', label: 'Verified Experts'},
    { data: '4.2', label: 'Average Rating'}
];

module.exports = {
    lorem, languages, cities, countries, channels, services, statistics
};