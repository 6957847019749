import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';

import difmLogo from '../../images/logo.png';
import { StaticQuery, graphql, Link, navigate } from 'gatsby';
import { Manager, Reference, Popper } from 'react-popper';
// import BrowserDetection from 'react-browser-detection';

import flg_en_BE from '../../images/Flag_of_England.svg';
import flg_nl_BE from '../../images/Flag_of_Netherlands.svg';
import flg_fr_BE from '../../images/Flag_of_France.svg';
import flg_de_DE from '../../images/Flag_of_Germany.svg';

// const browserHandler = {
//     chrome: () => <div>Chrome is fantastic!</div>,
//     googlebot: () => <div>Hi GoogleBot!</div>,
//     default: (browser) => <div>Hi {browser}!</div>,
//   };

const internals = {};

internals.getImage = (lang) => {

    switch (lang.toLowerCase()) {
        case 'nl_be':
            return flg_nl_BE;
        case 'fr_be':
            return flg_fr_BE;
        case 'de_de':
            return flg_de_DE;
        default:
            return flg_en_BE;
    }
}

class Header extends React.Component {

    state = {
        country: this.props.country || 'be',
        languageSelection: false,
        channel: this.props.channel || 'default',
        menu: 'none'
    }

    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }


    startLanguageSelection = () => {
        this.setState({ languageSelection: !this.state.languageSelection });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (event.target.id !== 'selectedLanguage') {
            this.setState({ languageSelection: false });
        }

        if (event.target.className !== 'mobile-menu-link' && event.target.className !== 'icon') {
            this.setState({
                menu: 'none'
            });
        }
        //     if(event.target.className==='icon'){
        //         if(this.state.menu==='block'){
        //             this.setState({ 
        //                 menu:'none'
        //             });
        //         } else {
        //             this.setState({ 
        //                 menu:'block'
        //             });
        //         }
        //   }
    }

    ToggleMenu = () => {
        if (this.state.menu === 'block') {
            this.setState({
                menu: 'none'
            });
        } else {
            this.setState({
                menu: 'block'
            });
        }

    }

    render() {

        const { t, language, country, channel } = this.props;
        const self = this;
        const opacity = this.state.languageSelection ? 1 : 0;

        const channel_path = `${(channel === 'default' ? '' : '/' + channel).toLowerCase()}`;
        const path = `/${language.toLowerCase()}/${country.toLowerCase()}${channel_path}`;

        const logoLink = `${path}/`;
        const servicesLink = `${path}/#services`;
        const faqLink = `${path}/faq`;
        const aboutLink = `${path}/about`;
        const contactLink = `${path}/#contact_us`;

        //const default_channel = channel_label;

        const channel_label = Config.channels.map(ch => {
            if (ch.code === channel) return ch.label;
            return null;
        })
        return (
            <>
                <header id="header">
                    <div className="container header">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-4 col-lg-3 d-flex align-items-center m-w-50 logo-box">
                                <Link className="logo-link" to={logoLink}>
                                    <img src={difmLogo} className="logo" style={{ height: '50px', margin: '0' }} alt="Do It For Me" />
                                </Link>

                                <span className="channel-name" style={{ display: `${channel_label[0] === 'DEFAULT' ? 'none' : 'block'}` }}>{channel_label}</span>
                            </div>
                            <div className="col-8 col-lg-9 header-nav">
                                <ul>
                                    <li><Link to={servicesLink}>{t('Services')}</Link></li>
                                    <li><Link to={faqLink}>{t('Support / FAQ')}</Link></li>
                                    <li><Link to={aboutLink}>{t('About Us')}</Link></li>
                                    <li><Link to='/blog'>{t('Blog')}</Link></li>
                                    <li>
                                        <Manager>
                                            <Reference>
                                                {({ ref }) => (
                                                    <div
                                                        className="selected-language d-flex align-items-center"
                                                        ref={ref}
                                                        id="selectedLanguage"
                                                        onClick={(e) => { self.startLanguageSelection(e) }}
                                                    >
                                                        <img alt={t(language)} src={internals.getImage(language)} />
                                                        {t(language)}
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                )}
                                            </Reference>
                                            <Popper>
                                                {({ ref, style, placement }) => (
                                                    <div className="language-selector" ref={ref} style={{ ...style, opacity }} data-placement={placement}>
                                                        {
                                                            Config.languages.map((lang) => {

                                                                let el = null;
                                                                if (lang.code !== language) {
                                                                    el = (
                                                                        <div key={lang.code} onClick={(e) => { e.preventDefault(); navigate(`/${lang.code.toLowerCase()}/${self.props.country}/${channel_path}`) }}>
                                                                            <img width="20" alt={t(lang.code)} src={internals.getImage(lang.code)} />
                                                                            {t(lang.code)}
                                                                        </div>
                                                                    );
                                                                }
                                                                return el;
                                                            })
                                                        }
                                                    </div>
                                                )}
                                            </Popper>
                                        </Manager>
                                    </li>
                                    <li className="contact_btn">
                                        <Link to={contactLink}><button className="btnContactUs">{t('Contact Us')}</button></Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="mobile col-4 m-lanuage-switch">
                                <Manager>
                                    <Reference>
                                        {({ ref }) => (
                                            <div
                                                className="selected-language d-flex align-items-center"
                                                ref={ref}
                                                id="selectedLanguage"
                                                onClick={(e) => { self.startLanguageSelection(e) }}
                                            >
                                                <img alt={t(language)} src={internals.getImage(language)} />
                                                {t(language)}
                                                <i className="la la-angle-down"></i>
                                            </div>
                                        )}
                                    </Reference>
                                    <Popper>
                                        {({ ref, style, placement }) => (
                                            <div className="language-selector" ref={ref} style={{ ...style, opacity }} data-placement={placement}>
                                                {
                                                    Config.languages.map((lang) => {

                                                        let el = null;
                                                        if (lang.code !== language) {
                                                            el = (
                                                                <div key={lang.code} onClick={(e) => { e.preventDefault(); navigate(`/${lang.code.toLowerCase()}/${self.props.country}/${channel_path}`) }}>
                                                                    <img width="20" alt={t(lang.code)} src={internals.getImage(lang.code)} />
                                                                    {t(lang.code)}
                                                                </div>
                                                            );
                                                        }
                                                        return el;
                                                    })
                                                }
                                            </div>
                                        )}
                                    </Popper>
                                </Manager>
                            </div>
                            <div className="mobile col-2">
                                <div className="">
                                    <div className="icon" onClick={(e) => { self.ToggleMenu(e) }}></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </header>
                <div className="mobile menu" style={{ display: `${this.state.menu}` }}>
                    <ul className="topnav responsive">
                        <li><Link className="mobile-menu-link" to={servicesLink}>{t('Services')}</Link></li>
                        <li><Link className="mobile-menu-link" to={faqLink}>{t('Support/FAQ')}</Link></li>
                        <li><Link className="mobile-menu-link" to={aboutLink}>{t('About Us')}</Link></li>
                        <li><Link className="mobile-menu-link" to='/blog'>{t('Blog')}</Link></li>
                        <li><Link className="mobile-menu-link" to={contactLink}>{t('Contact Us')}</Link></li>
                    </ul>
                </div>
            </>
        )
    }
};

Header.propTypes = {
    data: PropTypes.object,
    t: PropTypes.func,
    lang: PropTypes.string,
    y: PropTypes.number,
    fold: PropTypes.number,
    animate: PropTypes.bool
};

Header.defaultProps = {
    data: {},
    lang: 'en',
    y: 0,
    fold: 0,
    animate: false
};

export default props => (
    <StaticQuery
        query={graphql`
            query {
                logo: file(relativePath: { eq: "logo.png" }) {
                    childImageSharp {
                        fixed(width: 148, height: 64) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
            }
        `}
        render={data => <Header data={data} {...props} />}
    />
);
