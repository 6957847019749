import React from "react";
import { Link } from 'gatsby';
import "react-tabs/style/react-tabs.css";

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../../styles/swiper.min.css';

class AboutPage extends React.Component {
    constructor() {
        super();
        this.state = {
            selected: false,
            selected_box: 'tab-1',
        }
    }

    onClick(name) {
        this.setState({
            selected_box: name,

        })

    }
    render() {

        const { t } = this.props;
        return (
            <>
                <div>
                    <div className="clientDiv">
                        <div className="container">
                            <div className="desktop">
                                <div className="clearfix"></div>
                                <div className="faq-section">
                                    <ul className="faq-tabs">
                                        <div className="faq-head-tab">{t('FAQBlock1Title')}</div>
                                        <li className={`${this.state.selected_box === 'tab-1' ? 'selected' : ''}`} onClick={() => this.onClick("tab-1")}>{t('FAQBlock2Title')}</li>
                                        <li className={`${this.state.selected_box === 'tab-2' ? 'selected' : ''}`} onClick={() => this.onClick("tab-2")}>{t('FAQBlock3Title')}</li>
                                        <li className={`${this.state.selected_box === 'tab-3' ? 'selected' : ''}`} onClick={() => this.onClick("tab-3")}>{t('FAQBlock4Title')}</li>
                                        <li className={`${this.state.selected_box === 'tab-4' ? 'selected' : ''}`} onClick={() => this.onClick("tab-4")}>{t('FAQBlock5Title')}</li>
                                        <li className={`${this.state.selected_box === 'tab-5' ? 'selected' : ''}`} onClick={() => this.onClick("tab-5")}>{t('FAQBlock6Title')}</li>
                                    </ul>
                                    <div className="col_12 tab-content">
                                        <div style={{ display: `${this.state.selected_box === 'tab-1' ? 'block' : 'none'}` }} >
                                            <Accordion>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock2Art1Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock2Art1Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock2Art2Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock2Art2Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock2Art3Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock2Art3Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                        <div style={{ display: `${this.state.selected_box === 'tab-2' ? 'block' : 'none'}` }}>
                                            <Accordion className="hello" key='2'>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock3Art1Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock3Art1Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                        <div style={{ display: `${this.state.selected_box === 'tab-3' ? 'block' : 'none'}` }} >
                                            <Accordion key='3'>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock4Art1Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock4Art1Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock4Art2Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock4Art2Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                            </Accordion>

                                        </div>
                                        <div style={{ display: `${this.state.selected_box === 'tab-4' ? 'block' : 'none'}` }} >
                                            <Accordion key='4'>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock5Art1Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock5Art1Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                            </Accordion>

                                        </div>
                                        <div style={{ display: `${this.state.selected_box === 'tab-5' ? 'block' : 'none'}` }} >
                                            <Accordion key='5'>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock6Art1Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock6Art1Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        <h3 className="u-position-relative">
                                                            {t('FAQBlock6Art2Title')}
                                                            <div className="accordion__arrow" role="presentation" />
                                                        </h3>
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody>
                                                        <p>{t('FAQBlock6Art2Content')}</p>
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="greyBG">
                            <div className="servisesDivText mobile">{t('FAQBlock1Title')}</div>
                            <div className="container">
                                <div className="faq mobile">
                                    <Accordion>
                                        <AccordionItem className="first-item">
                                            <AccordionItemTitle>
                                                <h3 className="u-position-relative"> {t('FAQBlock2Title')}
                                                    <div className="accordion__arrow" role="presentation" />
                                                </h3>
                                            </AccordionItemTitle>
                                            <AccordionItemBody>
                                                <Accordion>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock2Art1Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock2Art1Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock2Art2Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock2Art2Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock2Art3Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock2Art3Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </AccordionItemBody>
                                        </AccordionItem>
                                        <AccordionItem className="second-item">
                                            <AccordionItemTitle>
                                                <h3 className="u-position-relative"> {t('FAQBlock3Title')}
                                                    <div className="accordion__arrow" role="presentation" />
                                                </h3>
                                            </AccordionItemTitle>
                                            <AccordionItemBody>
                                                <Accordion>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock3Art1Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock3Art1Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </AccordionItemBody>
                                        </AccordionItem>
                                        <AccordionItem className="third-item">
                                            <AccordionItemTitle>
                                                <h3 className="u-position-relative"> {t('FAQBlock4Title')}
                                                    <div className="accordion__arrow" role="presentation" />
                                                </h3>
                                            </AccordionItemTitle>
                                            <AccordionItemBody>
                                                <Accordion>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock4Art1Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock4Art1Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock4Art2Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock4Art2Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </AccordionItemBody>
                                        </AccordionItem>
                                        <AccordionItem className="fourth-item">
                                            <AccordionItemTitle>
                                                <h3 className="u-position-relative"> {t('FAQBlock5Title')}
                                                    <div className="accordion__arrow" role="presentation" />
                                                </h3>
                                            </AccordionItemTitle>
                                            <AccordionItemBody>
                                                <Accordion>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock5Art1Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock5Art1Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </AccordionItemBody>
                                        </AccordionItem>
                                        <AccordionItem className="fifth-item">
                                            <AccordionItemTitle>
                                                <h3 className="u-position-relative"> {t('FAQBlock6Title')}
                                                    <div className="accordion__arrow" role="presentation" />
                                                </h3>
                                            </AccordionItemTitle>
                                            <AccordionItemBody>
                                                <Accordion>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock6Art1Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock6Art1Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3 className="u-position-relative"> {t('FAQBlock6Art2Title')}
                                                                <div className="accordion__arrow" role="presentation" />
                                                            </h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <p>{t('FAQBlock6Art2Content')}</p>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </AccordionItemBody>
                                        </AccordionItem>
                                    </Accordion>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div>
                    <div className="container desktop">
                        <div className="bookServiceNowDiv">
                            <span>
                                {t('CTA Title')}
                            </span>
                            <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                <button type="button" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                                    {t('CTA Button')}
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="mobile">
                        <div className="bookServiceNowDiv">
                            <span>
                                {t('CTA Title')}
                            </span>
                            <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                <button type="button" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                                    {t('CTA Button')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default AboutPage;
