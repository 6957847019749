import React from "react";

import SEO from "../components/seo";
import Header from "../components/header";
import Footer from '../components/footer';
import Data from '../components/data';
import Contact from '../components/contact';

import Translate from '../utils/translate';

class Page extends React.Component {

    getCookieValue(name) {

        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
        return null;
    }

    async componentDidMount() {

        const { country, language, channel } = this.props.pageContext;

        const path = this.props.location.pathname;
        if (path === '/') {
            const prefs = this.getCookieValue('prefs');
            if (prefs) {
                const parts = prefs.toLowerCase().split(',');
                const lang = parts[1] || 'en_us';
                const country = parts[0] || 'be';
                const channel = parts[2] === 'default' ? '' : parts[2] + '/';    
                document.location.href = `/${lang}/${country}/${channel}${this.props.location.hash || ''}`;
            }
            else {
                document.location.href = '/en_us/be';
            }
        }
        const expiration_date = new Date();
        let cookie_string = '';
        expiration_date.setFullYear(expiration_date.getFullYear() + 1);
        cookie_string = `prefs=${country},${language},${channel}; path=/; expires=${expiration_date.toUTCString()}`;
        document.cookie = cookie_string;
    }

    scrollTop = () => {

        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    getSetting = (settings, name) => {

        for (let i = 0; i < settings.length; ++i) {
            if (settings[i].name === name) {
                return settings[i].value;
            }
        }
        return null;
    }

    render() {

        const { settings, countries, languages, country, language, channel, translations } = this.props.pageContext;
        const t = Translate(translations, language);
        const children = React.Children.map(this.props.children, child => {

            return React.cloneElement(child, {
                countries, languages, country, language, channel, t, location: this.props.location
            });
        });

        const show = this.props.hideHeader === true ? false : true;
        const showContact = this.props.hideContact === true ? false : true;
        const lang = language.substring(0, 2);
        const description = this.getSetting(settings, `seo_description_${lang}`);
 
        return (
            <>
                <SEO lang={language} description={description} title={t(`Installation service`)} keywords={[`do it for me`, `services`, `lawn mower`, `installation`]}  t={t} />
                { show && <Header countries={countries} languages={languages} location={this.props.location} country={country} language={language} channel={channel} t={t} /> }
                {children}
                { showContact && <Contact country={country} language={language} channel={channel} t={t} /> }
                { showContact && <Data country={country} language={language} channel={channel} t={t} /> }
                <Footer countries={countries} languages={languages} location={this.props.location} country={country} language={language} channel={channel} t={t} />
                <div className="toTop d-flex align-items-center justify-content-center" onClick={this.scrollTop}>
                    <i className="la la-angle-up"></i>
                </div>
            </>
        );
    }
}

export default Page;
