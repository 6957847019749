import React, { Component } from 'react';
import { Link } from 'gatsby';

export default class extends Component {

    render() {

        const { t } = this.props;
        return(
            <div className="supportCta d-flex flex-column align-items-center justify-content-center">
                <h1>{t('Service. Install. Repair')}</h1>
                <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                    <button type="button" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                        {t('CTA Button')}
                    </button>
                </Link>
            </div>
        )
    }
}
